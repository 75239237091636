import Vue from 'vue'
import BaseResource from '@sigmacloud/sigma-client/dist/resources'
import BaseReport from '@sigmacloud/sigma-client/dist/reports/index'
import ReportParamResource from '@sigmacloud/sigma-client/dist/resources/reports/reportparam'
import WaitOptions from '@sigmacloud/sigma-client/dist/resources/reports/reportrender'
import Sigma from '@sigmacloud/sigma-client'
import crypto from 'crypto'

export default Vue.extend({
    data() {
        return {
            reportLoading: false,
            loadingId: undefined,
            checkRunData: undefined,
            reports: [
                {
                    name: 'Custom Time Entry-Audit',
                    endpoint: '/reports/custom_time_entry',
                    optionalParameters: ['client', 'pay_period_start_date__gte', 'project', 'employee', 'payroll', 'check_date__lte', 'pay_period_end_date__lte', 'mask_ssn', 'check_date__gte'],
                    requiredParameters: [],
                    templateName: 'audit',
                },
                {
                    name: 'Detail Pay Recap - Billed',
                    endpoint: '/reports/detail_pay_recap',
                    optionalParameters: ['mask_ssn', 'batch', 'check_date__gte', 'payroll', 'check_date__lte', 'project', 'client'],
                    requiredParameters: [],
                    templateName: 'detail_pay_recap_billed ',
                },
                {
                    name: 'Detail Pay Recap - Actuals',
                    endpoint: '/reports/detail_pay_recap',
                    optionalParameters: ['mask_ssn', 'batch', 'check_date__gte', 'payroll', 'check_date__lte', 'project', 'client'],
                    requiredParameters: [],
                    templateName: 'detail_pay_recap_actuals',
                },
                {
                    name: 'Custom Final Invoice',
                    endpoint: '/reports/payroll/invoice',
                    optionalParameters: ['override_company_address_id'],
                    requiredParameters: ['batch'],
                    templateName: 'default',
                },
                {
                    name: 'Custom Final Invoice v2',
                    endpoint: '/reports/payroll/invoice-v2',
                    optionalParameters: ['override_company_address_id'],
                    requiredParameters: ['batch'],
                    templateName: 'default',
                },

                {
                    name: 'Payroll Wage Check Register',
                    endpoint: '/reports/payroll/check_register',
                    optionalParameters: ['pay_period_start_date__gte', 'mask_ssn', 'pay_period_end_date__lte', 'parent_client', 'project', 'payroll', 'check_date__lte', 'check_date__gte', 'employee', 'client'],
                    requiredParameters: [],
                    templateName: 'default',
                },
                {
                    name: '#2 Custom Time Entry - Summary Version',
                    endpoint: '/reports/custom_time_entry',
                    optionalParameters: ['client', 'pay_period_start_date__gte', 'project', 'employee', 'payroll', 'check_date__lte', 'pay_period_end_date__lte', 'mask_ssn', 'checkDate__gte'],
                    requiredParameters: [],
                    templateName: 'summary',
                },
                {
                    name: 'Payroll-Detail Check Register - Default/Detail',
                    endpoint: '/reports/payroll/detail_check_register',
                    optionalParameters: ['mask_ssn', 'project', 'check_date__gte', 'payroll', 'check_date__lte', 'employee', 'client'],
                    requiredParameters: [],
                    templateName: 'default',
                },
                {
                    name: 'Payroll-Detail Check Register - Summary',
                    endpoint: '/reports/payroll/detail_check_register',
                    optionalParameters: ['client', 'project', 'check_date__lte', 'payroll', 'mask_ssn', 'employee', 'check_date__gte'],
                    requiredParameters: [],
                    templateName: 'summary',
                },
                {
                    name: 'Fringe Recap',
                    endpoint: '/reports/payroll/fringe_recap',
                    optionalParameters: ['pay_period_start_date__gte', 'pay_period_end_date__lte', 'check_date__lte', 'payroll', 'employee', 'check_date__gte', 'project', 'client'],
                    requiredParameters: [],
                    templateName: 'detail',
                },
                {
                    name: 'Account Detail',
                    endpoint: '/reports/payroll/account_detail',
                    optionalParameters: [
                        'cat_j_from',
                        'cat_g_from',
                        'cat_l_from',
                        'cat_n_from',
                        'cat_l_to',
                        'payroll',
                        'cat_j_to',
                        'employee',
                        'cat_h_to',
                        'cat_m_to',
                        'cat_c_from',
                        'cat_k_to',
                        'cat_b_to',
                        'pay_period_start_date__gte',
                        'cat_n_to',
                        'cat_e_to',
                        'cat_c_to',
                        'cat_a_to',
                        'cat_d_to',
                        'cat_g_to',
                        'client',
                        'cat_h_from',
                        'cat_o_from',
                        'check_date__gte',
                        'cat_p_to',
                        'project',
                        'cat_a_from',
                        'check_date__lte',
                        'pay_period_end_date__lte',
                        'cat_e_from',
                        'cat_m_from',
                        'cat_i_from',
                        'cat_f_from',
                        'cat_d_to',
                        'cat_i_to',
                        'cat_p_from',
                        'cat_k_from',
                        'cat_b_from',
                        'cat_o_to',
                        'cat_f_to',
                    ],
                    requiredParameters: [],
                    templateName: 'default',
                    optionalParamDefaultValues: {
                        cat_a_from: 1,
                        cat_a_to: 50,
                        cat_b_from: 51,
                        cat_b_to: 100,
                        cat_c_from: 101,
                        cat_c_to: 113,
                        cat_d_from: 114,
                        cat_d_to: 139,
                        cat_e_from: 140,
                        cat_e_to: 150,
                        cat_f_from: 151,
                        cat_f_to: 167,
                        cat_g_from: 168,
                        cat_g_to: 180,
                        cat_h_from: 181,
                        cat_h_to: 192,
                        cat_i_from: 193,
                        cat_i_to: 210,
                        cat_j_from: 211,
                        cat_j_to: 216,
                        cat_k_from: 217,
                        cat_k_to: 226,
                        cat_l_from: 227,
                        cat_l_to: 233,
                        cat_m_from: 234,
                        cat_m_to: 270,
                        cat_n_from: 271,
                        cat_n_to: 276,
                        cat_o_from: 277,
                        cat_o_to: 281,
                        cat_p_from: 282,
                        cat_p_to: 329,
                    },
                },
                {
                    name: 'Account Detail by Job',
                    endpoint: '/reports/payroll/account_detail',
                    optionalParameters: [
                        'cat_j_from',
                        'cat_g_from',
                        'cat_l_from',
                        'cat_n_from',
                        'cat_l_to',
                        'payroll',
                        'cat_j_to',
                        'employee',
                        'cat_h_to',
                        'cat_m_to',
                        'cat_c_from',
                        'cat_k_to',
                        'cat_b_to',
                        'pay_period_start_date__gte',
                        'cat_n_to',
                        'cat_e_to',
                        'cat_c_to',
                        'cat_a_to',
                        'cat_d_to',
                        'cat_g_to',
                        'client',
                        'cat_h_from',
                        'cat_o_from',
                        'check_date__gte',
                        'cat_p_to',
                        'project',
                        'cat_a_from',
                        'check_date__lte',
                        'pay_period_end_date__lte',
                        'cat_e_from',
                        'cat_m_from',
                        'cat_i_from',
                        'cat_f_from',
                        'cat_d_to',
                        'cat_i_to',
                        'cat_p_from',
                        'cat_k_from',
                        'cat_b_from',
                        'cat_o_to',
                        'cat_f_to',
                    ],
                    optionalParamDefaultValues: {
                        cat_a_from: 1,
                        cat_a_to: 50,
                        cat_b_from: 51,
                        cat_b_to: 100,
                        cat_c_from: 101,
                        cat_c_to: 113,
                        cat_d_from: 114,
                        cat_d_to: 139,
                        cat_e_from: 140,
                        cat_e_to: 150,
                        cat_f_from: 151,
                        cat_f_to: 167,
                        cat_g_from: 168,
                        cat_g_to: 180,
                        cat_h_from: 181,
                        cat_h_to: 192,
                        cat_i_from: 193,
                        cat_i_to: 210,
                        cat_j_from: 211,
                        cat_j_to: 216,
                        cat_k_from: 217,
                        cat_k_to: 226,
                        cat_l_from: 227,
                        cat_l_to: 233,
                        cat_m_from: 234,
                        cat_m_to: 270,
                        cat_n_from: 271,
                        cat_n_to: 276,
                        cat_o_from: 277,
                        cat_o_to: 281,
                        cat_p_from: 282,
                        cat_p_to: 329,
                    },
                    requiredParameters: [],
                    templateName: 'job',
                },
                {
                    name: 'AICP Account Detail by Job',
                    endpoint: '/reports/payroll/account_detail',
                    optionalParameters: [
                        'cat_j_from',
                        'cat_g_from',
                        'cat_l_from',
                        'cat_n_from',
                        'cat_l_to',
                        'payroll',
                        'cat_j_to',
                        'employee',
                        'cat_h_to',
                        'cat_m_to',
                        'cat_c_from',
                        'cat_k_to',
                        'cat_b_to',
                        'pay_period_start_date__gte',
                        'cat_n_to',
                        'cat_e_to',
                        'cat_c_to',
                        'cat_a_to',
                        'cat_d_to',
                        'cat_g_to',
                        'client',
                        'cat_h_from',
                        'cat_o_from',
                        'check_date__gte',
                        'cat_p_to',
                        'project',
                        'cat_a_from',
                        'check_date__lte',
                        'pay_period_end_date__lte',
                        'cat_e_from',
                        'cat_m_from',
                        'cat_i_from',
                        'cat_f_from',
                        'cat_d_to',
                        'cat_i_to',
                        'cat_p_from',
                        'cat_k_from',
                        'cat_b_from',
                        'cat_o_to',
                        'cat_f_to',
                    ],
                    optionalParamDefaultValues: {
                        cat_a_from: 1,
                        cat_a_to: 50,
                        cat_b_from: 51,
                        cat_b_to: 100,
                        cat_c_from: 101,
                        cat_c_to: 113,
                        cat_d_from: 114,
                        cat_d_to: 139,
                        cat_e_from: 140,
                        cat_e_to: 150,
                        cat_f_from: 151,
                        cat_f_to: 167,
                        cat_g_from: 168,
                        cat_g_to: 180,
                        cat_h_from: 181,
                        cat_h_to: 192,
                        cat_i_from: 193,
                        cat_i_to: 210,
                        cat_j_from: 211,
                        cat_j_to: 216,
                        cat_k_from: 217,
                        cat_k_to: 226,
                        cat_l_from: 227,
                        cat_l_to: 233,
                        cat_m_from: 234,
                        cat_m_to: 270,
                        cat_n_from: 271,
                        cat_n_to: 276,
                        cat_o_from: 277,
                        cat_o_to: 281,
                        cat_p_from: 282,
                        cat_p_to: 329,
                    },
                    requiredParameters: [],
                    templateName: 'aicp',
                },
                {
                    name: 'Invoice Bundle:  PR Taxes and WC',
                    endpoint: '/reports/payroll/invoice',
                    optionalParameters: [],
                    requiredParameters: ['batch'],
                    templateName: 'default',
                },
                {
                    name: 'Detail Payroll Edit',
                    endpoint: '/reports/payroll/detail_edit',
                    optionalParameters: ['pay_period_start_date__gte', 'mask_ssn', 'pay_period_end_date__lte', 'batch', 'payroll', 'check_date__lte', 'check_date__gte', 'project', 'client'],
                    requiredParameters: [],
                    templateName: 'default',
                },
                {
                    name: 'Basic Benefits Report',
                    endpoint: '/reports/payroll/basic_benefits_report',
                    optionalParameters: ['payroll', 'laborunionlocal', 'work_date__lte', 'client', 'project', 'work_date__gte', 'batch', 'mask_ssn', 'check_date__lte', 'check_date__gte', 'feecategory'],
                    requiredParameters: [],
                    optionalParamDefaultValues: {
                        mask_ssn: false,
                    },
                    templateName: 'default',
                },
                {
                    name: 'WC Bundle Invoice',
                    endpoint: '/reports/payroll/wc_invoice/',
                    templateName: 'default',
                    requiredParameters: ['payroll', 'batch'],
                    optionalParameters: [],
                },
                {
                    name: 'Print Paychecks',
                    endpoint: '/reports/employee_check_stub_history',
                    optionalParameters: [],
                    requiredParameters: ['payroll'],
                    templateName: 'default',
                },
            ],
        }
    },
    methods: {
        async getReport(item) {
            let selectedReport = this.reports.find((report) => {
                return report.name === item.selected
            })

            let data = Object.assign({}, item)
            let payrollId = data.id
            // if (selectedReport.name === 'Print Paychecks') {
            //     this.getCheckRunQuote(payrollId)
            // }

            //Create cached parameters
            let reportParamRes = new ReportParamResource()
            let reportParamData = {
                payroll: payrollId,
            }
            let queryParams = { report_template: selectedReport.templateName }
            let batchRequired = selectedReport.requiredParameters.find((paramName) => {
                return paramName === 'batch'
            })

            if (data.batch && batchRequired) {
                reportParamData.batch = data.batch
            }

            if(data.check_ids_to_print.length > 0) {
              reportParamData.check_ids_to_print = data.check_ids_to_print
            }
            // Set optional params
            if (selectedReport.optionalParamDefaultValues) {
                for (let key of Object.keys(selectedReport.optionalParamDefaultValues)) {
                    reportParamData[key] = selectedReport.optionalParamDefaultValues[key]
                }
            }
            if (data.override_company_address_id) {
                reportParamData['override_company_address_id'] = data.override_company_address_id
            }
            reportParamRes.set('data', reportParamData)
            this.reportLoading = true
            this.loadingId = item.id

            try {
                // Save cached params
                let paramResponse = await reportParamRes.save()
                let paramsResource = paramResponse.resources[0]

                //new report resource wrap with report endpoint, await .wait()

                //Request report
                BaseReport.endpoint = selectedReport.endpoint
                let reportResource = await BaseReport.fromParamId(paramsResource.id, queryParams)
                let reportRenderResource = await reportResource.generate()
                let options = new WaitOptions()
                options.pollTimeout = 2400
                await reportRenderResource.wait(options).catch((error) => {
                    this.$emit('message', error)
                })
                let reportId = reportRenderResource.id
                let pdfResponse = await Sigma.client.get(`/reports/renders/${reportId}/body`)

                if (pdfResponse) {
                    let pdfBinaryFile = pdfResponse.data.body

                    const linkSource = `data:application/pdf;base64,${pdfBinaryFile}`
                    const downloadLink = document.createElement('a')
                    let randString = crypto.randomBytes(16).toString('hex')
                    const fileName = `${payrollId}-${selectedReport.name}-${randString}.pdf`

                    downloadLink.href = linkSource
                    downloadLink.download = fileName
                    downloadLink.click()
                }
                this.reportLoading = false
                this.loadingId = undefined
            } catch (error) {
                this.$emit('message', error)
                this.reportLoading = false
                this.loadingId = undefined
            }
        },
    },
})
