import Vue from 'vue'
import BaseResource from '@sigmacloud/sigma-client/dist/resources/base'
import BatchResource from '@sigmacloud/sigma-client/dist/resources/transactions/batch'
import BankResource from '@sigmacloud/sigma-client/dist/resources/system/bank'
import CheckResource from '@sigmacloud/sigma-client/dist/resources/transactions/check'

export default Vue.extend({
    data() {
        return {
            quotedChecks: [],
            checkRunData: undefined,
            payCheckResult: undefined,
            checksWithGeneratedCheckIds: [],
        }
    },
    methods: {
        async groupPayrollsByBank(payrolls) {
            let groups = {}
            for (let payroll of payrolls) {
                let batch = await BatchResource.detail(payroll.batch)
                let bank = batch.attributes.bank
                if (!groups[bank]) {
                    groups[bank] = [payroll]
                } else {
                    groups[bank].push(payroll)
                }
            }
            return groups
        },
        async getCheckNumbers(groupedPayrolls) {
            let bankIds = Object.keys(groupedPayrolls)
            let quotedChecks = []
            for (let bankId of bankIds) {
                let prIds = []
                for (let pr of groupedPayrolls[bankId]) {
                    prIds.push(pr.id)
                }
                let prQuote = await this.getCheckRunQuote(prIds)
                // console.log(prQuote)
                let numChecks = prQuote.length

                let bankRes = BankResource.wrap(`/${bankId}/checks?amount=${numChecks}`)
                let { data } = numChecks && (await bankRes.get())
                // console.log(data)
                for (let index = 0; index < numChecks; index++) {
                    prQuote[index].number = String(data[index])
                }
                quotedChecks.push(...prQuote)
            }
            this.quotedChecks = quotedChecks
            return quotedChecks
        },
        async getCheckRunQuote(payrollId) {
            //get check ids
            let endpoint = ''
            if (typeof payrollId === 'string' || typeof payrollId === 'number') {
                endpoint = `/aps/printable_paychecks?payroll=${payrollId}`
            } else if (payrollId.length && payrollId.length === 1) {
                endpoint = `/aps/printable_paychecks?payroll=${payrollId[0]}`
            } else if (payrollId.length && payrollId.length > 1) {
                endpoint = '/aps/printable_paychecks?payroll__in='
                endpoint += payrollId.join(',')
            } else {
                this.$emit('message', 'Unrecognized format for payroll IDs')
                return
            }
            let paycheckRes = BaseResource.wrap(endpoint)
            let paycheckResponse = await paycheckRes.get()
            if (paycheckResponse.status === 204) {
                this.$emit('message', ' YTD calculation for this Payroll is in progress. Please check back in couple of minutes.')
            }

            let paycheckIds = []
            this.payCheckResult = paycheckResponse.data.results
            for (let result of paycheckResponse.data.results) {
                paycheckIds.push(result.id)
            }
            //create checkrun
            let checkRunRes = BaseResource.wrap(`/runs/quote_payroll?pr__id__in=${payrollId}`)
            let crresponse = await checkRunRes.get()
            this.checkRunData = crresponse.data
            this.checksWithGeneratedCheckIds = []
            return crresponse.data.filter((ele) => {
                let hasGenerated_check_Ids = this.payCheckResult.find((pcr) => pcr.id === ele.related_transactions[0].id);
                if (hasGenerated_check_Ids == undefined || !hasGenerated_check_Ids.generated_checks_ids.length) {
                    return ele
                } else {
                    this.checksWithGeneratedCheckIds.push(hasGenerated_check_Ids.generated_checks_ids)
                }
            })
        },
        async saveChecks(checkData, reissue) {
            // console.log(this.payCheckResult)
            let checks = []
            let savedChecks = []
            for (let check of checkData) {
                this.payCheckResult.forEach((element) => {
                    if (element.meta_data) {
                        if (!element.generated_checks_ids.length && check.related_transactions[0].id === element.id) {
                            Object.assign(check, { reissue_of: element.meta_data.reissue_of_check })
                        } else {
                            Object.assign(check, { check_id: element.generated_checks_ids })
                        }
                    } else {
                        Object.assign(check, { new: true })
                    }
                })
                if (check.new || check.reissue_of) {
                    let newCheckResource = new CheckResource()
                    for (let attribute of Object.keys(check)) {
                        newCheckResource.set(attribute, check[attribute])
                    }
                    newCheckResource.set('status', 'DRAFT')
                    checks.push(newCheckResource)
                }
            }
            for (let check of checks) {
                await check.save()
                savedChecks.push(check)
            }
            if (!reissue && this.checksWithGeneratedCheckIds.length) {
                const checkDetailsById = await this.getCheckDetailsByIds(this.checksWithGeneratedCheckIds)
                for (let checkDetails of checkDetailsById) {
                    let exsitingCheckResource = new CheckResource()
                    for (let attribute of Object.keys(checkDetails)) {
                        exsitingCheckResource.set(attribute, checkDetails[attribute])
                    }
                    checks.push(exsitingCheckResource)
                    savedChecks.push(exsitingCheckResource)
                }
            }
            if (!reissue && checks.length) {
                for (let check of checks) {
                    check.set('status', 'POSTED')
                    await check.save()
                }
            }
            return savedChecks
        },
        async getCheckDetailsByIds(checkIds = []) {
            let checksDetails = []
            for (let checkId of checkIds) {
                let checkDetailsByID = await BaseResource.wrap(`/checks/${checkId}`).get()
                checksDetails.push(checkDetailsByID.data)
            }
            return checksDetails
        },
        async saveCheckRun(checks) {
            let checkIds = []
            for (let check of checks) {
                checkIds.push(check.attributes.id)
            }

            let checkRunResource = BaseResource.wrap('/runs')
            let response = checks.length && (await checkRunResource.post({ checks: [...checkIds] }))
            return response
        },
    },
})
